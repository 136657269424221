import React from "react";
import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <div class="container margin-home">
        <div class="banner">
          <div class="text">
            <h2>Te ayudamos para que tu hogar se haga realidad</h2>
            <p>
              Somos expertos en el área de la construcción, nos preocupamos de
              la calidad de tu vivienda.
            </p>
            <NavLink to="/consulta">
              <div style={{ width: "fit-content" }} class="btn btn-md full">
                Iniciar consulta
                <div class="flecha"></div>
              </div>
            </NavLink>
          </div>
          <img class="box_shadow" src={require("../../img/img1.png")} alt="" />
        </div>
        <div class="banner">
          <div class="text">
            <h2>No estás solo en tu sueño</h2>
            <p>
              Todas tus dudas las resolveremos desde los cimientos hasta las
              terminaciones.<br></br>Somos arquitectos, ingenieros,
              constructores con más de 20 años de experiencia listos para apoyar
              a tu familia.
            </p>
          </div>
          <img
            class="box_shadow"
            src={require("../../img/arquitectos.jpg")}
            alt=""
          />
        </div>
        {false && (
          <div class="banner">
            <div class="text">
              <h2>Quiénes Somos</h2>
              <p>
                Somos arquitectos, ingenieros, constructores con más de 20 años
                de experiencia listos para apoyar a tu familia.
              </p>
            </div>
            <img
              class="box_shadow"
              src={require("../../img/img2.png")}
              alt=""
            />
          </div>
        )}
        {false && (
          <div class="experiencias">
            <h2>Experiencias de la comunidad </h2>
            <div class="flex">
              <div class="caso">
                <div class="img-home-exp">
                  <img
                    class="box_shadow"
                    src={require("../../img/caso.png")}
                    alt=""
                  />
                </div>
                <div class="user">
                  <h4>Carlos Pérez</h4>
                  <p>Lorem Ipsum</p>
                </div>
                <p>
                  So I don't need to use many cards including credit cards, just
                  with one account I can make multiple payments. Very suitable
                  for me who doesn't like complicated things just for payment. I
                  love DGBBank
                </p>
              </div>
              <div class="caso">
                <div class="img-home-exp">
                  <img
                    class="box_shadow"
                    src={require("../../img/caso.png")}
                    alt=""
                  />
                </div>
                <div class="user">
                  <h4>Carlos Pérez</h4>
                  <p>Lorem Ipsum</p>
                </div>
                <p>
                  So I don't need to use many cards including credit cards, just
                  with one account I can make multiple payments. Very suitable
                  for me who doesn't like complicated things just for payment. I
                  love DGBBank
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div class="ayuda">
        <div class="container">
          <div class="box_ayuda">
            <h2>¿Necesitas ayuda?</h2>
            <div class="conversemos">
              <p>Conversemos:</p>

              <div class="box_conversemos">
                <div class="item">
                  <div class="icon icon-phone"></div>
                  <div class="datos">
                    <p>Llámanos:</p>
                    <div>
                      <p style={{ fontWeight: "300" }}>56-2-9439960</p>
                    </div>
                    <div>
                      <p style={{ fontWeight: "300" }}>56-2-9439950</p>
                    </div>
                    <div>
                      <p style={{ fontWeight: "300" }}>56-9-44898456</p>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="icon"></div>
                  <div class="datos">
                    <p>Escríbenos:</p>
                    <p>contacto@etexh.com</p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  color: "#fff",
                  fontSize: "17px",
                  marginTop: "5px",
                }}
              >
                Horario de atención L-V de 9:00 a 18:00
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="banner">
          <div class="text">
            <h2>Comienza a construir tu vivienda desde este botón</h2>

            <NavLink to="/consulta">
              <div
                style={{
                  width: "fit-content",
                  display: "flex",
                  marginTop: "20px",
                }}
                class="btn btn-md full"
              >
                Iniciar consulta
                <div class="flecha"></div>
              </div>
            </NavLink>
          </div>
          <img src={require("../../img/img1.png")} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
