import React from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <header class="box_shadow ">
      <div class="container">
        <div class="box_header">
          <NavLink to="/">
            <div id="logo"></div>
          </NavLink>
        </div>
      </div>
    </header>
  );
};

export default Header;
