import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Header,
  Footer,
  Home,
  Questions,
  Contact,
  Us,
  EducationalSession,
  Forms,
} from "./components";

import "./css/style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/nosotros" element={<Us />} />
      <Route path="/sesion-educativa" element={<EducationalSession />} />
      <Route path="/preguntas-frecuentes" element={<Questions />} />
      <Route path="/contacto" element={<Contact />} />
      <Route path="/consulta" element={<Forms />} />
      <Route path="*" element={<Home />} />
    </Routes>
    <Footer />
  </Router>
);
