import React, { useState, useEffect } from "react";
import Dropdown from "./components/Dropdown";
import ErrorForm from "./components/ErrorForm";
import Cxup from "../../api/cxup";

const TypeData = ({
  dataForm,
  setNextButton,
  setOption,
  setType,
  option,
  type,
}) => {
  let typeOptions = [
    {
      name: "Arquitectos",
      id: 1,
      options: [
        { id: 5, img: 5, name: "Diseño de habitaciones" },
        { id: 6, img: 6, name: "Normativa" },
        { id: 7, img: 7, name: "Terminaciones" },
        { id: 8, img: 8, name: "Cómo evito la humedad y hongos" },
        { id: 9, img: 9, name: "Cómo evito el ruido" },
        { id: 11, img: 11, name: "Otra consulta para un arquitecto/a" },
      ],
    },
    {
      name: "Obra Gruesa",
      id: 2,
      options: [
        { id: 12, img: 12, name: "Comparar materiales para construir" },
        { id: 13, img: 13, name: "Cómo evitar fisuras en losas y muros" },
        { id: 14, img: 14, name: "Suelos y excavaciones" },
        { id: 16, img: 10, name: "Otra consulta" },
      ],
    },
    {
      name: "Instalaciones",
      id: 3,
      options: [
        { id: 17, img: 15, name: "Servicios sanitarios - Agua Potable" },
        { id: 18, img: 16, name: "Servicios eléctricos" },
        { id: 19, img: 17, name: "Servicios de gas" },
        { id: 20, img: 10, name: "Otra consulta" },
      ],
    },
    {
      name: "Materiales y Productos",
      id: 4,
      options: [
        { id: 21, img: 18, name: "Cualidades y defectos de un producto" },
        { id: 22, img: 19, name: "Comparación de productos" },
        { id: 23, img: 19, name: "Otra consulta" },
        { id: 24, img: 22, name: "Quiero que me recomiende un producto para" },
      ],
    },
    ,
    {
      name: "Necesito revisar o comparar proyectos",
      id: 5,
      options: [],
    },
    {
      name: "Quiero que me contacten",
      id: 6,
      options: [],
    },
  ];

  const [show, setShow] = useState(false);
  const [typeSelected, setTypeSelected] = useState(null);
  const [dataType, setDataType] = useState(typeOptions);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    getSubTypes();
  }, []);

  const getSubTypes = async () => {
    const response = await Cxup.getSubTypes();
    console.log("response", response);

    response.forEach((element) => {
      typeOptions.forEach((typeOption) => {
        typeOption.options.forEach((option) => {
          if (element.name.toLowerCase() === option.name.toLowerCase()) {
            option._id = element._id;
            if (
              typeOption.name.toLowerCase() === element.type.name.toLowerCase()
            )
              typeOption._id = element.type._id;
          }
        });
      });
    });

    console.log("typeOptions", typeOptions);
  };

  const handleShow = (value) => {
    console.log("handleShow", value);

    if (value === "Necesito revisar o comparar proyectos") {
      setTypeSelected(value);
      setType({
        id: 5,
        img: 5,
        name: "Necesito revisar o comparar proyectos",
        _id: "6410ccbc0078300e138c3b9d",
      });
      setOption({
        id: 5,
        img: 5,
        name: "Necesito revisar o comparar proyectos",
        _id: "6411eaaed61ddb1987a697f7",
      });
      setNextButton(3);
    }
    if (value === "Quiero que me contacten") {
      setTypeSelected(value);
      setType({
        id: 6,
        img: 5,
        name: "Quiero que me contacten",
        _id: "643d5f913d4f6d1ec0238a72",
      });
      setOption({
        id: 6,
        img: 5,
        name: "Otra consulta",
        _id: "643d5fe03d4f6d1ec0238a74",
      });
      setNextButton(3);
    }

    if (show && typeSelected === value) {
      setShow(false);
    } else {
      setShow(true);
    }
    setTypeSelected(value);
  };

  const handleClick = (value, type) => {
    console.log("value", value);
    console.log("type", type);
    setType(type);
    setOption(value);
  };

  const handleNextButton = () => {
    if (option && option.id < 28) {
      if (option.id === 17 || option.id === 18 || option.id === 19) {
        setNextButton(4);
        dataForm.consultation = "";
      } else {
        setNextButton(3);
      }
    } else {
      setErrorMessage(true);
    }
  };

  return (
    <div class="container">
      <p class="miga">
        Consultas <strong>/ Tipo de consulta</strong>
      </p>
      <div class="box_shadow container_consulta">
        <p class="t-large-bold">
          2. ¡Hola! {dataForm.name} ¿Qué tipo de especialidad quieres consultar?
        </p>
        <p class="t-large-normal">
          Despliega una de las especialidades para ver el tipo de consulta que
          necesitas realizar. <br /> *Sólo debes elegir una alternativa.
        </p>{" "}
        <br />
        {errorMessage === true && (
          <ErrorForm
            setErrorMessage={setErrorMessage}
            text="Debes seleccionar al menos una opción"
          />
        )}
        <div class="box_consulta">
          <div class="column">
            <ul class="bc_list">
              <Dropdown
                dataType={dataType}
                handleShow={handleShow}
                handleClick={handleClick}
                typeSelected={typeSelected}
                show={show}
                optionSelected={option}
              />
            </ul>
          </div>
          <div class="column hide-mobile">
            <img class="bc_image" src={require("../../img/img5.png")} alt="" />
          </div>
        </div>
      </div>
      <div class="form_btns">
        <button
          className="btn btn-l lineal form_btns_size center-mobile width-100 margin-top"
          onClick={() => setNextButton(1)}
        >
          Volver atras
        </button>
        <button
          type="button"
          className="btn btn-l full form_btns_size center-mobile width-100 margin-top margin-bottom"
          onClick={handleNextButton}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default TypeData;
