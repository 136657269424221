import React from "react";
import { useState, useEffect } from "react";
import CommitteeData from "./CommitteeData";
import ConsultationError from "./components/ConsultationError";
import ConsultationSuccess from "./components/ConsultationSuccess";
import InstallationData from "./InstallationData";
import LastForm from "./LastForm";
import TypeData from "./TypeData";
import Cxup from "../../api/cxup";

const Forms = () => {
  const [nextButton, setNextButton] = useState(1);
  const [type, setType] = useState(null);
  const [option, setOption] = useState(null);
  const [consultationSuccess, setConsultationSuccess] = useState(false);
  const [consultationError, setConsultationError] = useState(false);
  const [selectedFileForm, setSelectedFileForm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [dataForm, setDataForm] = useState({
    name: "",
    lastName: "",
    committeeName: "",
    committeeNumber: "",
    email: "",
    phone: "",
    consultation: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [nextButton]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [consultationSuccess]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [consultationError]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setDataForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    setDataForm((prevState) => ({
      ...prevState,
      type: type.name,
      option: option.name,
    }));
    submitData();
  };

  const submitData = async (e) => {
    setIsLoading(true);

    let data = {
      name: `${dataForm.name} ${dataForm.lastName}`,
      email: dataForm.email,
      type: type._id,
      subtype: option._id,
      description: dataForm.consultation,
      phone: `+56 9 ${dataForm.phone}`,
      filler2: {
        name: "Número Comité",
        value: dataForm.committeeNumber,
      },
      filler1: {
        name: "Nombre Comité",
        value: dataForm.committeeName,
      },
    };

    try {
      const response = await Cxup.createCase(data);
      console.log("response", response);
      if (response && !response.code) {
        console.log("selectedFileForm", selectedFileForm);
        console.log(" file.originFileObj", selectedFileForm);
        if (selectedFileForm) {
          const res = await Cxup.createFileCxup(response, selectedFileForm);
          console.log("res", res);
        }
        setConsultationSuccess(true);
      } else {
        console.log("errorr");
        setConsultationError(true);
      }
      setIsLoading(true);
    } catch (e) {
      setConsultationError(true);
      setIsLoading(false);
    }

    //endpoint a cxup
    // setConsultationSuccess(true); //si el endpoint responde con exito
    //setConsultationError(true); //si el endpoint responde con error
  };

  return (
    <div className="margin-home">
      {nextButton === 1 &&
        consultationSuccess === false &&
        consultationError === false && (
          <CommitteeData
            dataForm={dataForm}
            handleChange={handleChange}
            setNextButton={setNextButton}
          />
        )}
      {nextButton === 2 &&
        consultationSuccess === false &&
        consultationError === false && (
          <TypeData
            dataForm={dataForm}
            setNextButton={setNextButton}
            setOption={setOption}
            option={option}
            type={type}
            setType={setType}
          />
        )}
      {nextButton === 3 &&
        consultationSuccess === false &&
        consultationError === false && (
          <LastForm
            handleChange={handleChange}
            setNextButton={setNextButton}
            type={type}
            option={option}
            onSubmit={handleSubmit}
            dataForm={dataForm}
            setSelectedFileForm={setSelectedFileForm}
            isLoading={isLoading}
          />
        )}
      {nextButton === 4 &&
        consultationSuccess === false &&
        consultationError === false && (
          <InstallationData
            setNextButton={setNextButton}
            type={type}
            option={option}
            setOption={setOption}
            dataForm={dataForm}
          />
        )}
      {consultationSuccess && <ConsultationSuccess />}
      {consultationError && (
        <ConsultationError
          setConsultationError={setConsultationError}
          setNextButton={setNextButton}
        />
      )}
    </div>
  );
};

export default Forms;
