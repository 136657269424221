import React from "react";
import { NavLink } from "react-router-dom";

const ConsultationSuccess = () => {
  return (
    <div class="container">
      <p class="miga">Consulta enviada con éxito</p>
      <div class="box_shadow enviado">
        <h4>Tu consulta fue ingresada con éxito</h4>
        <p>
          Uno de nuestros especialistas se contactará contigo a través del
          correo electrónico que ingresaste.
        </p>
        <img src={require("../../../img/check.png")} alt="" />
        <div class="form_btns_success">
          <NavLink className="btn btn-md lineal sesion" to="/">
            Volver al Home
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ConsultationSuccess;
