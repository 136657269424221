import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import ErrorForm from "./components/ErrorForm";

const CommitteeData = ({
  dataForm,
  handleChange,
  setNextButton,
  handleError,
}) => {
  const [errorMessage, setErrorMessage] = useState(false);

  const handleNextButton = () => {
    if (
      dataForm.name !== null &&
      dataForm.name !== "" &&
      dataForm.lastName !== null &&
      dataForm.lastName !== "" &&
      dataForm.committeeName !== null &&
      dataForm.committeeName !== "" &&
      dataForm.committeeNumber !== null &&
      dataForm.committeeNumber !== "" &&
      dataForm.email !== null &&
      dataForm.email !== "" &&
      dataForm.phone !== null &&
      dataForm.phone !== ""
    ) {
      setNextButton(2);
    } else {
      setErrorMessage(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      dataForm.name !== null &&
      dataForm.name !== "" &&
      dataForm.lastName !== null &&
      dataForm.lastName !== "" &&
      dataForm.committeeName !== null &&
      dataForm.committeeName !== "" &&
      dataForm.committeeNumber !== null &&
      dataForm.committeeNumber !== "" &&
      dataForm.email !== null &&
      dataForm.email !== "" &&
      dataForm.phone !== null &&
      dataForm.phone !== ""
    ) {
      setNextButton(2);
    } else {
      setErrorMessage(true);
    }

    // Aquí podrías enviar los datos del formulario a un servidor, por ejemplo
  };

  return (
    <div class="container">
      <div class="miga title-miga">
        Consultas <strong>/ Datos principales</strong>
      </div>
      <div
        class="box_shadow container_consulta"
        style={{ marginBottom: "80px" }}
      >
        <p class="t-large-bold">
          1. ¡Hola! Necesitamos algunos datos para saber un poco más de ti.
        </p>
        {errorMessage === true && (
          <ErrorForm
            setErrorMessage={setErrorMessage}
            text="Faltan datos por completar, por favor verifica que todos los campos estén correctos."
          />
        )}
        <form onSubmit={handleSubmit}>
          <div class="column">
            <div class="check padding-input">
              <label class="t-small">Nombre:</label>
              <input
                class="input_form"
                type="text"
                name="name"
                id="name"
                value={dataForm.name}
                onChange={handleChange}
                maxLength="20"
                required
              />
            </div>

            <div class="check padding-input">
              <label class="t-small">Apellido:</label>
              <input
                class="input_form"
                type="text"
                name="lastName"
                id="lastName"
                value={dataForm.lastName}
                onChange={handleChange}
                maxLength="20"
                required
              />
            </div>
          </div>
          <div class="column">
            <div class="check padding-input">
              <label class="t-small">Correo electrónico:</label>
              <input
                class="input_form"
                type="email"
                name="email"
                id="email"
                value={dataForm.email}
                onChange={handleChange}
                maxLength="50"
                required
              />
            </div>
            <div class="check padding-input">
              <label class="t-small">Número de contacto:</label>
              <input
                class="input_form"
                type="tel"
                name="phone"
                id="phone"
                value={dataForm.phone}
                onChange={handleChange}
                maxLength="9"
                minLength="9"
                required
              />
            </div>
          </div>
          <div class="column">
            <div class="check padding-input">
              <label class="t-small">Número del comité:</label>
              <input
                class="input_form"
                type="number"
                name="committeeNumber"
                id="committeeNumber"
                value={dataForm.committeeNumber}
                onChange={handleChange}
                maxLength="20"
                required
              />
            </div>
            <div class="check padding-input">
              <label class="t-small">Nombre del comité:</label>
              <input
                class="input_form"
                type="text"
                name="committeeName"
                id="committeeName"
                value={dataForm.committeeName}
                onChange={handleChange}
                maxLength="20"
                required
              />
            </div>
          </div>
          <div class="form_btns">
            <NavLink
              className="btn btn-l lineal form_btns_size center-mobile width-100"
              to="/"
            >
              Volver al Home
            </NavLink>
            <button
              type="submit"
              class="btn btn-l full form_btns_size center-mobile width-100 margin-top"
            >
              Siguiente
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CommitteeData;
