import React from "react";

const Questions = () => {
  return (
    <div style={{ padding: '150px', textAlign: 'center'}}>
      PÁGINA PREGUNTAS FRECUENTES
    </div>
  );
}

export default Questions;