import React, { useState } from "react";
import ErrorForm from "./components/ErrorForm";

const InstallationData = ({
  setNextButton,
  type,
  option,
  setOption,
  dataForm,
}) => {
  const [installationOption, setInstallationOption] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const waterOptions = [
    { id: 24, img: 20, name: "Cómo evitar filtraciones de agua" },
    { id: 25, img: 20, name: "Otra consulta" },
  ];
  const electricOptions = [
    { id: 26, img: 21, name: "Cómo evitar cortocircuitos" },
    { id: 27, img: 21, name: "Otra consulta" },
  ];
  const gasOptions = [
    { id: 28, img: 22, name: "Cómo evitar fugas de gas" },
    { id: 29, img: 22, name: "Otra consulta" },
  ];

  const handleClick = (value) => {
    setInstallationOption(value);
  };

  return (
    <div class="container">
      <p style={{ display: "flex", gap: "5px" }} class="miga">
        Consultas <strong>/ Instalaciones</strong>
        <img src={require("../../img/3.png")} alt="" />
      </p>
      <div class="box_shadow container_consulta">
        <p class="t-large-bold">
          3. Respecto al especialista elegido “
          {option.id === 17 || option.id === 24 || option.id === 25
            ? "Sanitario - Agua Potable"
            : option.id === 18 || option.id === 26 || option.id === 27
            ? "Instalador Eléctrico"
            : "Instalador de Gas"}
          ” <br /> ¿Qué tipo de consulta quieres realizar?
        </p>
        {errorMessage === true && (
          <ErrorForm
            setErrorMessage={setErrorMessage}
            text="Debes seleccionar al menos una opción"
          />
        )}
        <form class="form-consultas">
          <div class="column">
            <div>
              <label>Tengo una consulta sobre:</label>
            </div>
            <ul>
              {(option.id === 17 || option.id === 24 || option.id === 25) &&
                waterOptions.map((option) => {
                  return (
                    <li>
                      <div class="check" onClick={() => handleClick(option)}>
                        <input type="radio" id={option.id} name="tema" />
                        <label for={option.id}>{option.name}</label>
                      </div>
                    </li>
                  );
                })}
              {(option.id === 18 || option.id === 26 || option.id === 27) &&
                electricOptions.map((option) => {
                  return (
                    <li>
                      <div class="check" onClick={() => handleClick(option)}>
                        <input type="radio" id={option.id} name="tema" />
                        <label for={option.id}>{option.name}</label>
                      </div>
                    </li>
                  );
                })}
              {(option.id === 19 || option.id === 28 || option.id === 29) &&
                gasOptions.map((option) => {
                  return (
                    <li>
                      <div class="check" onClick={() => handleClick(option)}>
                        <input type="radio" id={option.id} name="tema" />
                        <label for={option.id}>{option.name}</label>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center" }}
            class="column"
          >
            <img
              src={require(`../../img/${
                option.id === 17 || option.id === 24 || option.id === 25
                  ? 15
                  : option.id === 18 || option.id === 26 || option.id === 27
                  ? 16
                  : 17
              }.png`)}
              alt=""
            />
          </div>
        </form>
      </div>
      <div class="form_btns">
        <button className="btn btn-l lineal" onClick={() => setNextButton(2)}>
          Volver atras
        </button>
        <button
          type="button"
          className="btn btn-l full"
          onClick={() => {
            if (installationOption !== null) {
              setNextButton(3);
              dataForm.consultation = `${installationOption.name}: `;
            } else {
              setErrorMessage(true);
            }
          }}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default InstallationData;
