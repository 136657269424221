import React from "react";

const Dropdown = ({
  dataType,
  handleShow,
  handleClick,
  show,
  typeSelected,
  optionSelected,
}) => {
  const romanize = (num) => {
    if (isNaN(num)) return NaN;
    var digits = String(+num).split(""),
      key = [
        "",
        "C",
        "CC",
        "CCC",
        "CD",
        "D",
        "DC",
        "DCC",
        "DCCC",
        "CM",
        "",
        "X",
        "XX",
        "XXX",
        "XL",
        "L",
        "LX",
        "LXX",
        "LXXX",
        "XC",
        "",
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
      ],
      roman = "",
      i = 3;
    while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
  };

  return (
    <li class="item">
      {dataType.map((item) => {
        return (
          <div key={item.name}>
            <p class="select_click" onClick={() => handleShow(item.name)}>
              {romanize(item.id)}. {item.name}
              <img
                class="bc_list_image"
                src={require(`../../../img/${
                  typeSelected === item.name && show === true ? "-" : "+"
                }.png`)}
                alt=""
              />
            </p>
            {typeSelected === item.name && show === true && (
              <ul class="bc_list_select">
                {item.options.map((option) => {
                  return (
                    <li key={option.name}>
                      <div
                        class="check"
                        onClick={() => handleClick(option, item)}
                      >
                        {optionSelected && optionSelected.id === option.id ? (
                          <input
                            type="radio"
                            id={option.id}
                            name="tema"
                            checked
                          />
                        ) : (
                          <input type="radio" id={option.id} name="tema" />
                        )}
                        <label htmlFor={option.id}>{option.name}</label>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        );
      })}
    </li>
  );
};

export default Dropdown;
