import React from "react";

const Footer = () => {
  return (
    <footer>
      <div class="container">
        <div class="logo"></div>
        <p>© 2023 EtexH. Todos los derechos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
