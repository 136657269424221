import React from "react";

const ConsultationError = ({ setConsultationError, setNextButton }) => {
  return (
    <div class="container">
      <p class="miga">Ocurrió un error</p>
      <div class="box_shadow enviado">
        <h4>
          Hubo un error, por favor vuelve a <br /> intentarlo
        </h4>
        <img src={require("../../../img/wrong.png")} alt="" />
        <div class="form_btns_success">
          <button
            className="btn btn-l lineal"
            onClick={() => {
              setConsultationError(false);
              setNextButton(3);
            }}
          >
            Volver atras
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConsultationError;
