import React from "react";

const EducationalSession = () => {
  return (
    <div style={{ padding: '150px', textAlign: 'center'}}>
      PÁGINA SESIÓN EDUCATIVA
    </div>
  );
}

export default EducationalSession;