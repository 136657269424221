import React from "react";

const ErrorForm = ({ setErrorMessage, text }) => {
  return (
    <div class="error-message">
      <div class="error-message-text">
        <div class="error-logo">
          <div id="error-logo"></div>
        </div>
        <div class="error-logo">{text}</div>
      </div>
      <div
        class="error-logo"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setErrorMessage(false);
        }}
      >
        <img src={require("../../../img/x.png")} alt="" />
      </div>
    </div>
  );
};

export default ErrorForm;
