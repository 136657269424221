import React from "react";
import { useState } from "react";
import ErrorForm from "./components/ErrorForm";

const LastForm = ({
  handleChange,
  setNextButton,
  type,
  option,
  onSubmit,
  dataForm,
  setSelectedFileForm,
  isLoading,
}) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);

  const changeHandler = (event) => {
    console.log("event", event);
    const file = event.target.files[0];

    if (file.size > 1024 * 1024 * 15) {
      alert("El archivo no debe ser mayor a 15 MB");
      event.target.value = null;
      return;
    }

    setSelectedFile(event.target.files[0]);
    setSelectedFileForm(event.target.files[0]);
    setIsFilePicked(true);
  };

  const formarNameFile = (name) => {
    if (name.includes(" ")) {
      return name;
    } else {
      if (name.length > 15) {
        return name.slice(0, 15) + "...";
      } else {
        return name;
      }
    }
  };

  return (
    <div class="consultas">
      <div class="container">
        <p style={{ display: "flex", gap: "5px" }} class="miga  title-miga">
          Consultas <strong>/ {type.name}</strong>
          <img src={require(`../../img/${type.id}.png`)} alt="" />
        </p>
        <div class="box_shadow container_consulta">
          <p class="t-large-bold">
            {" "}
            {option.img >= 20 && option.img <= 22 ? "4" : "3"}. En base a la
            selección anterior:
          </p>
          {errorMessage === true && (
            <ErrorForm
              setErrorMessage={setErrorMessage}
              text="Faltan datos por completar"
            />
          )}
          <form class="box_consulta">
            <div class="column">
              <div class="inputs">
                <p>{option.name}</p>
                <div class="check">
                  <label class="t-small-bold">Déjanos tu consulta:</label>
                  <textarea
                    rows="10"
                    type="text"
                    value={dataForm.consultation}
                    id="consultation"
                    name="consultation"
                    placeholder="Escribe tu mensaje aquí"
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div class="check">
                  <label class="t-small-bold">
                    Si necesitas subir un archivo hazlo aquí
                    <p>(max 15 MB)</p>
                  </label>
                  <div class="upload" style={{ marginTop: "10px" }}>
                    <div class="icon"></div>
                    {!isFilePicked && <span>Subir archivo aquí</span>}
                    <input
                      onChange={changeHandler}
                      class="input-file"
                      type="file"
                      id="Productos"
                      name="tema"
                    />
                    {isFilePicked && (
                      <div class="file-text">
                        <div>{formarNameFile(selectedFile.name)}</div>
                      </div>
                    )}
                  </div>
                </div>
                {/*<div class="check">
              <label class="t-small-bold">Si necesitas subir un archivo hazlo aquí:</label>
              <div class="upload">
                <div class="icon"> </div><span>Subir archivo aquí</span>
                <input class="input-file" type="file" id="Productos" name="tema" />
              </div>
            </div>*/}
              </div>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center" }}
              class="column hide-mobile"
            >
              <img src={require(`../../img/${option.img}.png`)} alt="" />
            </div>
          </form>
        </div>
        <div class="form_btns">
          <button
            className="btn btn-l lineal form_btns_size center-mobile width-100"
            onClick={() => {
              if (option.img >= 20 && option.img <= 22) {
                setNextButton(4);
              } else {
                setNextButton(2);
              }
            }}
          >
            Volver atras
          </button>
          <button
            disabled={isLoading}
            type="button"
            className="btn btn-l full form_btns_size center-mobile width-100 margin-top"
            onClick={() => {
              if (
                dataForm.consultation !== null &&
                dataForm.consultation !== ""
              ) {
                onSubmit();
              } else {
                setErrorMessage(true);
              }
            }}
          >
            {isLoading ? " Cargando..." : "Siguiente"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LastForm;
