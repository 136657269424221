export default class Cxup {
  static async getSubTypes() {
    return fetch(
      `https://backend.cxup.io/v1/web/subtypes-cases/onlyweb/todoingenieria`,
      {
        method: "get",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": "BC2EF9B28C59E8B756C57C47BDF10D86D518B1E2",
        }),
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        console.log("json", json);
        return json;
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }
  static async createCase(data) {
    return fetch(`https://backend.cxup.io/v1/web/cases/form/todoingenieria`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": "BC2EF9B28C59E8B756C57C47BDF10D86D518B1E2",
      }),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        console.log("json", json);
        return json;
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }
  static async createFileCxup(id, file) {
    const formData = new FormData();
    formData.append("files", file);

    return fetch(
      `https://backend.cxup.io/v1/s3/web/upload/todoingenieria/${id}`,
      {
        method: "POST",
        body: formData,
        headers: new Headers({
          "api-key": "BC2EF9B28C59E8B756C57C47BDF10D86D518B1E2",
        }),
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function (json) {
        console.log("json", json);
        return json;
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }
}
