import React from "react";

const Us = () => {
  return (
    <div style={{ padding: '150px', textAlign: 'center'}}>
      PÁGINA NOSOTROS
    </div>
  );
}

export default Us;